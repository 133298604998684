import https from "./https"
import store from "@/store"

const gift = {
  getGifts(params) {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/gifts', {params})
  },
  getGift(id) {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/gifts/' + id)
  },
  updateGift(id, params) {
    const organization = store.state.general.organization
    return https.put('admin/organizations/' + organization.id + '/gifts/' + id, params)
  },
  createGift(params) {
    const organization = store.state.general.organization
    return https.post('admin/organizations/' + organization.id + '/gifts/', params)
  },
  uploadImageByBase64(id, image) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/gifts/${id}/images/upload-by-base64`, {image})
  },
}

export default gift
