import imageApi from "@/apis/image";

export default {
  methods: {
    async uploadImage(image) {
      try {
        let response = await imageApi.upload(image);
        return response.data.data.image.url
      } catch (error) {
        this.$swal.fire({
          title: "上傳圖片失敗",
          type: "error",
        });
      }
    },
    async uploadByBase64(image) {
      try {
        let response = await imageApi.uploadByBase64(image);
        return response.data.data.image.url
      } catch (error) {
        this.$swal.fire({
          title: "上傳圖片失敗",
          type: "error",
        });
      }
    },
  }
}
