<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'GiftList',
              }"
            >贈品管理
            </b-breadcrumb-item
            >
            <b-breadcrumb-item active>贈品資料</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row mb-2 align-items-center">
          <div class="col-12 col-xl-2">
            <h4 class="font-weight-bold">贈品明細</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-form-group :disabled="readonly">
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="贈品名稱*"
              >
                <b-form-input v-model="gift.name"></b-form-input>
                <b-form-invalid-feedback :state="!v$.gift.name.$error">此欄位為必填</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="活動ID"
              >
                <b-form-input v-model="gift.event_id"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="所需點數"
              >
                <b-form-input type="number" v-model="gift.point_need"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="贈品剩餘數量*"
              >
                <b-form-input type="number" v-model="gift.quantity"></b-form-input>
                <b-form-invalid-feedback :state="!v$.gift.quantity.$error">此欄位為必填</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="贈品初始數量*"
              >
                <b-form-input type="number" v-model="gift.original_quantity"></b-form-input>
                <b-form-invalid-feedback :state="!v$.gift.original_quantity.$error">此欄位為必填</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="類別"
              >
                <b-select :options="typeOptions" v-model="gift.type"></b-select>
                <!-- <b-form-input type="number" v-model="gift.type"></b-form-input> -->
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="排序"
              >
                <b-form-input type="number" v-model="gift.sort"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="補充資料"
              >
                <b-form-textarea v-model="gift.data"></b-form-textarea>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="是否啟用"
              >
                <b-form-checkbox v-model="gift.is_enabled" switch></b-form-checkbox>
              </b-form-group>
              <b-form-group>
                <div class="row mb-2 align-items-end">
                  <div class="col-8 d-flex items-center" style="flex-direction: column">
                    <h4 class="font-weight-bold">設定贈品照片</h4>
                  </div>
                </div>

                <img class="s-cropped-image" :src="image" />

                <!-- 發現手機板chrome 的click 似乎與draggable 處理event 衝突, 先簡單用touchstart 解掉 -->
                <ImageSelector @input="handleImagesChange($event)">
                  <template #trigger="{ openFileSelector }">
                    <button
                      class="btn s-trigger-btn"
                      :disabled="!canClick"
                      @click="openFileSelector"
                    >
                      <template v-if="canClick">
                        <div v-if="image">
                          <div class="add-image-icon">＋</div>
                          <div>更換圖片</div>
                        </div>
                        <div v-else>
                          <div class="add-image-icon">＋</div>
                          <div>上傳圖片</div>
                        </div>
                      </template>
                      <template v-else> 上傳中... </template>
                    </button>
                  </template>
                </ImageSelector>
              </b-form-group>
            </b-form-group>
          </div>
        </div>
        <div class="d-flex justify-content-center" v-if="!readonly" style="margin-top: 80px">
          <b-button class="mr-4" variant="outline-danger" @click="handleCancel">
            <template>返回</template>
          </b-button>
          <b-button variant="success" @click="handleSubmit">
            <template v-if="isEdit">確認編輯</template>
            <template v-if="isCreate">確認新增</template>
          </b-button>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import giftApi from "../../../apis/gift";
import {zh} from "vuejs-datepicker/dist/locale";
import {mapGetters, mapState} from "vuex";
import getHours from "date-fns/getHours/index";
import getMinutes from "date-fns/getMinutes/index";
import {format} from "date-fns";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ImageSelector from "@/components/ImageSelector";
import imageMixin from "@/mixins/uploadImage";

export default {
  components: { ImageSelector },
  mixins: [imageMixin],
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      zh,
      showLoading: false,
      gift: {},
      image: null,
      canClick: true,
      canUpload: true,
      typeOptions: [
        {
          text: '點數兌換',
          value: 1
        }
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters('route', ['routeQuery']),
    readonly() {
      return this.$route.name === "GiftDetail"
    },
    isEdit() {
      return this.$route.name === "GiftEdit"
    },
    isCreate() {
      return this.$route.name === "GiftCreate"
    }
  },
  mounted() {
    if (!this.isCreate) {
      this.fetchGift()
    }
  },
  validations() {
    return {
      gift: {
        name: {required},
        quantity: {required},
        original_quantity: {required},
      }
    }
  },
  watch: {
    async image(val) {
      if (val) {
        if (val.startsWith("data:image/jpeg;base64")) {
          await this.handleImagesChange(val);
        }
      }
    },
  },
  methods: {
    format,
    async handleImagesChange(imageBase64) {
      try {
        this.canClick = false;
        const imageUrl = await this.uploadByBase64(imageBase64);
        if (!imageUrl) {
          return;
        }
        this.image = imageUrl;
        this.gift.image_url = imageUrl;
        // await giftApi.uploadImageByBase64(
        //   this.gift.id,
        //   imageBase64
        // );

      } catch (err) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "上傳圖片失敗",
        });
      } finally {
        this.canClick = true;
      }
    },
    formatToDateObject(datetime) {
      return {
        date: new Date(datetime),
        time: {
          HH: String(getHours(new Date(datetime))).padStart(2, "0"),
          mm: String(getMinutes(new Date(datetime))).padStart(2, "0"),
        },
      }
    },
    async fetchGift() {
      this.showLoading = true;

      try {
        const {data} = await giftApi.getGift(this.$route.params.gift_id);
        this.gift = data;
        this.image = this.gift.image_url

      } catch (error) {
        console.error(error);
        alert("取得群贈品料錯誤");
      }
      this.showLoading = false;
    },
    handleCancel() {
      this.$router.push({name: "GiftList", query: this.routeQuery});
    },
    async handleSubmit() {
      this.showLoading = true;

      if (this.isEdit) {
        await this.goUpdate()
      }
      if (this.isCreate) {
        await this.goCreate()
      }

      this.showLoading = false;
    },
    async goUpdate() {
      try {
        await giftApi.updateGift(this.gift.id, this.gift);
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            this.$router.push({name: "GiftList", query: this.routeQuery});
          });
      } catch (error) {
        console.error(error);
      }
    },
    async goCreate() {
      let result = await this.v$.$validate();
      if (!result) {
        this.showLoading = false;
        return;
      }
      try {
        await giftApi.createGift(this.gift);
        this.$swal
          .fire({
            icon: "success",
            text: "贈品建立成功",
          })
          .then(() => {
            const params = {name: "GiftList", query: this.routeQuery};
            this.$router.push(params);
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style lang="scss">
.image-selector {
  .image {
    position: relative;
    width: 30%;
    button {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
    }
  }
  .selector {
    width: 30%;
  }
}

.s-cropped-image {
  width: 100%;
  height: 100%;
}
</style>
