import https from "./https"

const image = {
  upload(image) {
    let data = new FormData();
    data.append("image", image);

    return https.post('general/images/upload', data)
  },
  uploadByBase64(image) {
    return https.post('general/images/upload-by-base64', { image })
  },
  uploadByBase64WithoutStoreToDb(image) {
    return https.post('admin/images/base64', { image })
  }
}

export default image
